import { Transition } from '@headlessui/react';
import type { RouteComponentProps } from '@reach/router';
import { navigate } from '@reach/router';
import { queryStoreLazy } from '@svelte/service/backend/queryStore';
import type { NavigationMenuQuery } from '@svelte/service/cms/graphql/gql';
import { Container } from 'components/atoms/layout/Container';
import { FormGroupHeading } from 'components/molecules/forms/Groups';
import {
  CheckoutLoader
} from 'components/molecules/orders/checkout/CheckoutLoader';
import {
  CheckoutContainer
} from 'components/molecules/orders/checkout/CheckoutWrapper';
import { StepContentWrapper } from 'components/molecules/orders/checkout/Step';
import OrderAmounts from 'components/molecules/orders/review/Amounts';
import OrderConfirmationItems
  from 'components/molecules/orders/review/OrderConfirmationItems';
import type { AppSiteNavItem } from 'gatsby/types';
import { loggers } from 'lib/log';
import { useReadable } from 'lib/react-svelte/reactifyStores';
// biome-ignore lint/style/useImportType: <explanation>
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';
import checkoutStore, { updateCheckoutInProgress } from 'state/stores/checkout';
import { get } from 'svelte/store';

import OrderConfirmationError from './Error';
import PaymentFailed from './PaymentFailed';

const log = loggers.service;

type Props = RouteComponentProps & {
	homeRoute: AppSiteNavItem;
	cartRoute: AppSiteNavItem;
	paymentRoute: AppSiteNavItem;
	supportRoute: AppSiteNavItem;
	title: string;
	footerNavigation: NavigationMenuQuery;
};

const OrderConfirmation: React.FC<Props> = ({
	homeRoute,
	cartRoute,
	footerNavigation,
	paymentRoute,
	supportRoute,
	title,
}) => {
	const queryWorker = useGraphqlWorker();
	const svelteQueryStore = useRef(
		queryStoreLazy({
			key: "orderConfirmation",
			worker: queryWorker,
		}),
	);
	const confirmationQuery = useReadable(svelteQueryStore.current);
	const [stateTitle, setStateTitle] = useState("Checking payment details");
	const [loading, setLoading] = useState(true);
	const orderId = new URLSearchParams(window.location.search).get("orderId");

	/**
	 * Stripe will set redirect_status when payment
	 * has gone through third party like Klarna, and possibly Paypal.
	 * If payment is with card, then no query parameter exists.
	 */
	const stripeRedirectStatus = new URLSearchParams(window.location.search).get(
		"redirect_status",
	);
	const paymentHasFailed =
		stripeRedirectStatus && stripeRedirectStatus !== "succeeded";

	const order = confirmationQuery.data?.orders.orderById;
	const error = confirmationQuery.error;

	useEffect(() => {
		if (get(checkoutStore).checkoutInProgress) {
			updateCheckoutInProgress(false);
		}

		if (!paymentHasFailed) {
			if (orderId) {
				confirmationQuery.start({
					id: orderId,
				});
			} else {
				log.error(new Error("Missing order id in url"), {
					orderId,
					stripeRedirectStatus,
				});
				navigate(cartRoute.path);
				return;
			}
		}
	}, [
		paymentHasFailed,
		orderId,
		cartRoute.path,
		confirmationQuery.start,
		stripeRedirectStatus,
	]);

	useEffect(() => {
		if (!!order || !!error) {
			setLoading(false);
		}
		if (order) {
			const msg = "Order confirmed";
			log.info(msg);
			setStateTitle(msg);
			const intervalId = setInterval(() => {
				// biome-ignore lint/suspicious/noExplicitAny: EF is a third part script
				const EF = (window as any).EF;
				if (typeof EF !== "undefined") {
					clearInterval(intervalId);
					EF.conversion({
						aid: "290",
						amount: order.amount.items / 100,
						order_id: order.id,
						adv1: "GBP",
						adv2: order.id,
						parameters: { currency: "GBP" },
					});
				}
			}, 1000);
		}
	}, [order, error]);

	/**
	 * Handle Klarna redirect
	 */
	if (paymentHasFailed) {
		log.warn("Payment failed", {
			orderId,
			stripeRedirectStatus,
		});
		return (
			<CheckoutContainer
				isOrderConfirmation={false}
				isCart={false}
				footerNavigation={footerNavigation}
				title={title}
			>
				<StepContentWrapper>
					<PaymentFailed paymentRoute={paymentRoute} />
				</StepContentWrapper>
			</CheckoutContainer>
		);
	}

	return (
		<CheckoutContainer
			isCart={false}
			isOrderConfirmation
			footerNavigation={footerNavigation}
			title={title}
		>
			<StepContentWrapper>
				{!error && <FormGroupHeading>{stateTitle}</FormGroupHeading>}

				<Transition
					className="max-w-lg"
					as={"div"}
					show={!loading}
					enter="transition-opacity duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					{error && (
						<OrderConfirmationError
							error={error}
							homeRoute={homeRoute}
							supportRoute={supportRoute}
						/>
					)}
					{order && (
						<OrderAmounts
							discount={order.discount}
							amounts={order.amount}
							className="pt-rhythm1"
						/>
					)}
				</Transition>

				{/* Loader */}
				<div className="relative w-full">
					<CheckoutLoader message="Please wait" show={loading} />
				</div>
			</StepContentWrapper>

			<div className="lg:col-start-1 lg:row-start-2">
				{order && (
					<Container>
						<OrderConfirmationItems order={order} />
					</Container>
				)}
			</div>
		</CheckoutContainer>
	);
};

export default OrderConfirmation;
